<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          Финансовая грамотность и как ее повысить?
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <div class="module_one_plot">
            <a href="#" class="module_one_plot_module">
              О курсе
            </a>
            <a href="#" class="module_one_plot_about">
              Модуль 1
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 2
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 3
            </a>
            <a href="#" class="module_one_plot_module">
              Итоговое тестирование
            </a>
          </div>
        </div>
        <div class="review_module_desc">
          <div class="review_module_desc_text_container">
            <div class="theme_one_desc_title">
              Отзыв по модулю 1
            </div>
            <div class="theme_one_desc_text">
              1. Как Вы оцениваете качество предоставленного материала?
            </div>
            <div class="my_courses_list_radio_block">
              <div class="my_courses_radio_item">
                <input type="radio" name="course_review" id="5" class="my_courses_list_radio"/>
                <label for="8" class="my_courses_radio_label">Отлично</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="course_review" id="4" class="my_courses_list_radio"/>
                <label for="36" class="my_courses_radio_label">Хорошо</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="course_review" id="3" class="my_courses_list_radio"/>
                <label for="40" class="my_courses_radio_label">Удовлетворительно</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="course_review" id="2" class="my_courses_list_radio"/>
                <label for="72" class="my_courses_radio_label">Плохо</label>
              </div>
              <div class="review_list_questions">
                <span class="review_list_questions_prev">1</span>
                <span class="review_list_questions_prev">2</span>
                <span class="review_list_questions_current">3</span>
                <span class="review_list_questions_next">4</span>
                <span class="review_list_questions_next">5</span>
                <span class="review_list_questions_next">6</span>
                <span class="review_list_questions_next">7</span>
                <span class="review_list_questions_next">8</span>
                <span class="review_list_questions_next">9</span>
                <span class="review_list_questions_next">10</span>
                <span class="review_list_questions_next">11</span>
                <span class="review_list_questions_next">12</span>
                <span class="review_list_questions_next">13</span>
                <span class="review_list_questions_next">14</span>
                <span class="review_list_questions_next">15</span>
              </div>
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="review_desc_btn_back">Назад</a>
              <a href="#" class="theme_one_desc_btn_next">Далее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Review"
}
</script>

<style scoped>

</style>
